<div
  class="modal fade theme-popup"
  [class.show]="showModal"
  [style.display]="showModal ? 'block' : 'none'"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          {{ title | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="requestCloseModal()">
        </button>
      </div>
      <div class="modal-body">
        <app-form
          [fields]="fields"
          [formGroup]="formGroup"
          (submitFormRequest)="requestSubmitForm()"
          (clickOnRadioButton)="clickOnRadioButton($event)"
          >
        </app-form>
      </div>
      <div class="modal-footer">
        <app-cancel-button
          [label]="cancelButtonTitle"
          [dismissModal]="true"
          (cancelRequest)="requestCloseModal()">
        </app-cancel-button>
        <app-saving-button
          [isValid]="formGroup.valid"
          [label]="submitButtonTitle"
          [savingLabel]="submitButtonLoadingText"
          (saveRequest)="requestSubmitForm()">
        </app-saving-button>
      </div>
    </div>
  </div>
</div>
@if(showModal){
<div class="modal-backdrop fade show"></div>
}
