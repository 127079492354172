import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextBoxComponent } from '../../forms/controls/text-box/text-box.component';
import { UiStore } from '../../../../core/store/ui.store';
import { SavingButtonComponent } from '../../buttons/saving-button/saving-button.component';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';
import { FormComponent } from '../../forms/form/form.component';

@Component({
  selector: 'app-form-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    TextBoxComponent,
    SavingButtonComponent,
    CancelButtonComponent,
    FormComponent,
  ],
  templateUrl: './form-modal.component.html',
  styleUrl: './form-modal.component.scss',
})
export class FormModalComponent {
  @Input() showModal: boolean;
  @Input() fields: any;
  @Input() formGroup: FormGroup;
  @Input() showForm: boolean = true;

  @Input() title: string = '';
  @Input() submitButtonTitle: string = '';
  @Input() submitButtonLoadingText: string = '';
  @Input() cancelButtonTitle: string = '';
  @Output() formSubmitRequest = new EventEmitter<any>();
  @Output() closeModalRequest = new EventEmitter<any>();
  @Output() clickOnRadioButtonRequest = new EventEmitter<any>();

  constructor(public uiStore: UiStore) {}

  requestCloseModal() {
    this.closeModalRequest.emit();
  }

  requestSubmitForm() {
    this.formSubmitRequest.emit();
  }

  clickOnRadioButton(optionId: number) {
    this.clickOnRadioButtonRequest.emit(optionId);
  }

 
}
